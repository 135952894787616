import React from 'react';

import { mui } from '../libs';

type LogoProps = {
  color?: string;
};

/**
 * Logo component that displays the Finsera logo.
 *
 * @param color - Optional color to apply to the logo.
 *
 * @returns Rendered Logo component displaying the Finsera logo.
 */
const Logo: React.FC<LogoProps> = ({ color }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <>
      <svg width="18" height="22" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M13.7659 0C13.0004 0 12.3798 0.620569 12.3798 1.38608V12.8924C12.3798 13.6579 13.0004 14.2785 13.7659 14.2785C14.5314 14.2785 15.152 13.6579 15.152 12.8924V1.38608C15.152 0.620569 14.5314 0 13.7659 0ZM5.52541 7.0632C4.7599 7.0632 4.13933 7.68377 4.13933 8.44928V17.829C4.13933 18.5946 4.7599 19.2151 5.52541 19.2151C6.29092 19.2151 6.91149 18.5946 6.91149 17.829V8.44928C6.91149 7.68377 6.29092 7.0632 5.52541 7.0632ZM1.38608 14.4684C0.620569 14.4684 0 15.089 0 15.8545V22.9557C0 23.7212 0.620569 24.3418 1.38608 24.3418C2.15159 24.3418 2.77216 23.7213 2.77216 22.9557V15.8545C2.77216 15.089 2.15159 14.4684 1.38608 14.4684ZM8.24075 11.2595C8.24075 10.494 8.86132 9.87339 9.62683 9.87339C10.3923 9.87339 11.0129 10.494 11.0129 11.2595V28.6139C11.0129 29.3794 10.3923 30 9.62683 30C8.86132 30 8.24075 29.3794 8.24075 28.6139V11.2595ZM12.3798 20.1835C12.3798 19.418 13.0004 18.7974 13.7659 18.7974C14.5314 18.7974 15.152 19.418 15.152 20.1835C15.152 20.949 14.5314 21.5696 13.7659 21.5696C13.0004 21.5696 12.3798 20.949 12.3798 20.1835ZM17.9051 5.7722C17.1396 5.7722 16.519 6.39277 16.519 7.15828V12.8925C16.519 13.658 17.1396 14.2785 17.9051 14.2785C18.6706 14.2785 19.2911 13.658 19.2911 12.8925V7.15828C19.2911 6.39277 18.6706 5.7722 17.9051 5.7722Z"
          fill={color ? color : (theme.palette as any).primary.light}
        />
      </svg>
    </>
  );
};

export default Logo;
