import React from 'react';
import { argsToStringifiable } from '@local/finsera-core/src/helpers/embed/utils';
import { _, mui } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';

import Chart from './chart';
import { useWindowDimensions } from '../../../hooks';
import CenteredLoader from '../../centered-loader';
import ChartError from '../../chart-error';

type NestedBarChartProps = {
  id: string;
  data: types.widgets.TableData;
  fullScreen: boolean;
  readOnly: boolean;

  alert?: {
    type: 'error' | 'warning';
    message: string;
  };

  tooltipText?: string;
  hideLegend?: boolean;
  hideSeries?: string[];
  numberFormat?: string;
  rotateXLabels?: boolean;
  exportTitle?: string;
  exportSubTitle?: string;
  download?: boolean;
  category?: string;
  yAxisLabel?: string;
  xAxisLabel?: string;
  setExternalEmbed?: (_data: types.analysis.EmbedChartData) => void;
  seriesOrder?: (_c: string[]) => string[];
  forceColor?: Record<string, string>;
};

const NestedBarChart: React.FC<NestedBarChartProps> = (args: NestedBarChartProps) => {
  const {
    id,
    data,
    fullScreen,
    readOnly,

    alert,
    tooltipText = '[bold]{name}:[/] {valueY}',
    hideSeries,
    hideLegend,
    category = 'history',
    numberFormat = '#.##',
    rotateXLabels,
    exportTitle,
    exportSubTitle,
    download = true,
    setExternalEmbed,
    yAxisLabel,
    xAxisLabel,
    seriesOrder = (c: string[]) => c,
    forceColor = {},
  } = args;

  const [localData, setLocalData] = React.useState<types.widgets.TableData>(null);

  const { width } = useWindowDimensions(1_000);

  React.useEffect(() => {
    const handleData = async () => {
      setLocalData(null);
      if (_.isNil(data)) return;
      await new Promise((resolve) => setTimeout(resolve, 200));

      if (setExternalEmbed)
        setExternalEmbed({
          id,
          widgetTitle: exportTitle,
          widgetSubTitle: exportSubTitle,
          chart_type: 'nested-bar-chart-v5',
          args: argsToStringifiable({
            ...args,
            data,
          }),
        });

      setLocalData(data);
    };

    handleData();
  }, [data, width]);

  return (
    <mui.core.Box
      sx={{
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        display: 'grid',
        gridTemplateRows: `${alert ? 'auto ' : ''} 1fr`,
      }}
    >
      {alert && <ChartError readOnly={readOnly} error={alert.message} severity={alert.type} />}
      <mui.core.Box>
        <mui.core.Box sx={{ height: fullScreen ? 'calc(100% - 40px)' : '100%' }}>
          {_.isNil(localData) ? (
            <CenteredLoader label="Loading widget data..." top="-40px" />
          ) : (
            <Chart
              id={id}
              data={localData}
              fullScreen={fullScreen}
              numberFormat={numberFormat}
              download={download}
              rotateXLabels={rotateXLabels}
              yAxisLabel={yAxisLabel}
              xAxisLabel={xAxisLabel}
              category={category}
              hideSeries={hideSeries}
              hideLegend={hideLegend}
              tooltipText={tooltipText}
              seriesOrder={seriesOrder}
              forceColor={forceColor}
            />
          )}
        </mui.core.Box>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default NestedBarChart;
