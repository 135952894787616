import React from 'react';
import { mui } from '@local/finsera-core/src/libs';

import { SECTION_BG } from '../../theme/layout-size';

/**
 * Props interface for the FINDataGrid component
 * @interface FINDataGridProps
 * @extends {muiDatagrid.DataGridPremiumProps}
 */

/**
 * A customized DataGrid component with consistent styling
 * @component
 * @param {FINDataGridProps} props - Component props
 * @returns {JSX.Element} Rendered DataGrid component
 */
const FinDataGrid: React.FC<mui.dataGrid.DataGridPremiumProps & { defaultBackgroundColor?: boolean }> = ({
  sx,
  defaultBackgroundColor,
  ...props
}) => {
  return (
    <>
      <mui.dataGrid.DataGridPremium
        density="compact"
        sx={{
          ...(!defaultBackgroundColor && {
            '& .MuiDataGrid-columnHeader': {
              // borderBottom: `1px solid ${theme.palette.gray.main}`,
              // background: `${SECTION_BG}!important`,
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'red!important', // Your custom background color
              // opacity: 0.1,
              backgroundOpacity: '0.1',
              // color: 'red', // Optional: custom text color
            },
          }),
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            // color: theme.palette.text.secondary,
          },
          '& .MuiDataGrid-row:nth-child(even)': {
            backgroundColor: SECTION_BG, // Light gray for odd rows
          },
          ...(sx || {}),
        }}
        {...props}
      />
    </>
  );
};

export default FinDataGrid;
