// FINSERA_LEGACY
export const PRIMARY_NAV_WIDTH = '40px';
export const LEFT_DRAWER_WIDTH = '230px';
export const LEFT_DRAWER_COLLAPSED_WIDTH = '34px';
export const APP_BAR_HEIGHT = '42px';
export const REPORT_BAR_HEIGHT = '50px';
export const TAB_HEIGHT = '41px';
export const REPORT_TAB_HEIGHT = 42;
export const EDITOR_FONT_SIZE = 14;
export const ASSISTANT_WIDTH = 500;
export const ASISTANT_Z_INDEX = 1010;
export const SIDEBAR_COLLAPSE_PADDING = '24px';
export const SIDEBAR_TAB_SIZE = '50px';
export const INPUT_SAFE_CONTAINER = '900px';
export const DRAG_DROP_HEIGHT = 32;
export const LARGE_DRAG_DROP_HEIGHT = 44;
export const SECURE_FORM_SIZE = 600;
export const MIN_TABLE_HEIGHT = 200;

// DARK MODE
export const SECTION_BG = 'rgba(27, 31, 55, 0.02)';
export const PORTFOLIO_CONTAINER = 'lg';
// export const SECTION_BG = 'rgba(244, 247, 251, 0.07)';
